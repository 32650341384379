'use strict';

var teasersModules = require('./teasersModules');

function teasersPLP() {
    // product grid teaser stars ONLY
    var allTeaserDivs = document.getElementsByClassName('TTteaser');
    if (allTeaserDivs.length > 0) {
        for (var i = 0; i < allTeaserDivs.length; i++) {
            var teaserDiv = allTeaserDivs[i];
            if ('starrating' in teaserDiv.dataset && teaserDiv.dataset.starrating > 0 && teaserDiv.children.length <= 0) {
                var el = teasersModules.generateTeaserStars(teaserDiv.dataset.starrating);
                teaserDiv.appendChild(el);
            }
        }
    }
}

module.exports = {
    teasersPLP: teasersPLP
};