'use strict';

var teasersPLPModule = require('./teaser/teasersPLPModule');

/**
 * @name teasersProductGrid.js
 * @description helper script to render reviews stars per product tile
 * @note this script is identical to the core teasersProductGrid.js script, but it is needed in this particular directory for proper webpack compilation
 */

/* Javascript to load on page load*/
$(document).ready(function () {
    teasersPLPModule.teasersPLP();
});
